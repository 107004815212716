import React from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import CategorySection from "../CategorySection/CategorySection"
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb"
import Separator from "~/components/Separator/Separator"
import { Container, FormattedBody, FormattedHeading } from "~/components/ui"
import { PartialRecipe } from "~/models/PartialRecipe"
import RecipeCarousel from "~/templates/Recipe/RecipeCarousel/RecipeCarousel"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./HomeHub.module.scss"

interface PropTypes extends TemplateDefaultPropTypes {
  data: HomeHubQuery
  pageContext: TemplateDefaultPropTypes["pageContext"] & {
    lang: string
    slug: string
    categoryId: string
  }
}

const HomeHub = ({ data, ...rest }: PropTypes) => {
  const intl = useIntl()

  return (
    <div className={css.root}>
      <Breadcrumb
        template="Recipe"
        pageTitle={intl.formatMessage({
          id: "path:home-hub",
        })}
      />
      <Container>
        <FormattedHeading translationId="hub/text:home-title" />
        <FormattedBody translationId="hub/text:home-description" />

        <Separator />
        <div>
          {data.categoriesLevel1.nodes.map((category, index) => {
            const subCategories = data.categoriesLevel2.nodes.filter(
              subCategory => subCategory.parentSlug === category.slug
            )
            if (!subCategories.length) {
              return null
            }
            return (
              <section className={css.categoryContainer} key={index}>
                <CategorySection
                  home
                  Category={category}
                  part1={subCategories.splice(0, 6)}
                  part2={subCategories.splice(0, 6)}
                />
              </section>
            )
          })}
        </div>
      </Container>
      <section className={css.carouselContainer}>
        <RecipeCarousel
          title={intl.formatMessage(
            {
              id: "hub/text:last-recipes",
            },
            {
              category: "",
            }
          )}
          recipes={data.allCategoriesCarousel.nodes}
          fullWidthClickable
          button={{
            label: intl.formatMessage({ id: "action:load-more" }),
            link: "recipes/recipe",
          }}
        />
      </section>
    </div>
  )
}

interface HomeHubQuery {
  categoriesLevel1: {
    nodes: {
      parentSlug: string
      description: string
      slug: string
      name: string
      coverImage: string
    }[]
  }
  categoriesLevel2: {
    nodes: {
      parentSlug: string
      slug: string
      name: string
      coverImage: string
    }[]
  }
  allCategoriesCarousel: { totalCount: number; nodes: PartialRecipe[] }
}

export const query = graphql`
  query HomeHub($language: String) {
    categoriesLevel1: allCategory(
      filter: { node_level: { eq: 1 }, language: { eq: $language } }
    ) {
      nodes {
        parentSlug
        slug
        name
        description
        coverImage
      }
    }
    categoriesLevel2: allCategory(
      filter: { node_level: { eq: 2 }, language: { eq: $language } }
    ) {
      nodes {
        parentSlug
        slug
        name
        coverImage
      }
    }
    allCategoriesCarousel: allRecipe(
      filter: { language: { eq: $language } }
      limit: 5
    ) {
      totalCount
      nodes {
        ...PartialRecipe
      }
    }
  }
`

export default withTemplate(HomeHub)
